import React, { useEffect, useState } from "react";
import { Link } from "@reach/router";

import { Card, Header, Icon } from "semantic-ui-react";

const Posts = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const getPosts = async () => {
      const resp = await fetch(
        "https://serverless-api.kostispaschalakis99.workers.dev/posts"
      );
      const postsResp = await resp.json();
      setPosts(postsResp.reverse());
    };

    getPosts();
  }, []);

  return (
    <div>
      <Header as="h2" icon textAlign="center" style={{ margin: 15 }}>
        <Icon name="users" circular />
        <Header.Content>Cloudflare Social Media</Header.Content>
      </Header>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
          // alignContent: "center"
        }}
      >
        {posts.map(post => (
          <div key={post.id} style={{ margin: 15 }}>
            <Card>
              <Card.Content>
                <Card.Header>{post.title}</Card.Header>
                <Card.Meta>
                  <span className="date">{post.username}</span>
                </Card.Meta>
                <Card.Description>{post.content}</Card.Description>
              </Card.Content>
            </Card>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Posts;
